import React, { Component } from 'react'
import NewLayout from '../layout/newLayout'
import PageContents from '../components/pageContents'
import styles from '../styles/scss/pages/contactUs.module.scss'
import cn from "classnames"

class Confirm extends Component {

    render() {
        const pg = this.props.pageContext.page
        return (
			<NewLayout>
                <div className='d-flex flex-column flex-fill'>
					<div className={styles.container}>
						<>
							<div className={cn(styles.formItems, "row")}>
								<div className={styles.formContainer}>
                                    <PageContents content={pg.content} />
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </NewLayout>
		);
    }
}

export default Confirm